import React from 'react'
import Head from 'next/head'
import { NextPageContext } from 'next/types'
import { ApolloClient, NormalizedCacheObject, ApolloProvider } from '@apollo/client'
import { getDataFromTree } from '@apollo/client/react/ssr'
import { Request } from 'express'
import initApollo from './apollo'

export interface Props {
	serverState?: any
	xavierId?: string
	router?: any
	isMobile?: boolean
	isTablet?: boolean
}

export type CustomPageContext = NextPageContext & {
	router: any
}

function getComponentDisplayName(Component: any) {
	return Component.displayName || Component.name || 'Unknown'
}

export function withApollo(apolloConfig: any) {
	return function composedComponent<T>(ComposedComponent: any) {
		return class WithData extends React.Component<Props & T> {
			static displayName = `WithData(${getComponentDisplayName(ComposedComponent)})`

			static async getInitialProps(ctx: CustomPageContext) {
				const { router, req } = ctx
				let serverState = { apollo: { data: {} } }
				let isMobile = false
				let isTablet = false
				let xavierId = ''

				// Evaluate the composed component's getInitialProps()
				let composedInitialProps = {}
				if (ComposedComponent.getInitialProps) {
					composedInitialProps = await ComposedComponent.getInitialProps(ctx)
				}

				// Run all GraphQL queries in the component tree
				// and extract the resulting data
				if (!process.browser) {
					isMobile = (req as Request).isMobile
					isTablet = (req as Request).isTablet
					xavierId = (req as Request).cookies['xavier-id'] || (req as Request).header('x-xavier-id')
					const apollo = initApollo(apolloConfig, null, ctx, {
						headers: { 'x-xavier-id': xavierId },
					})

					try {
						// Run all GraphQL queries
						await getDataFromTree(
							<ApolloProvider client={apollo}>
								<ComposedComponent router={router} {...composedInitialProps} />
							</ApolloProvider>,
						)
					} catch (error) {
						// Prevent Apollo Client GraphQL errors from crashing SSR.
						// Handle them in components via the data.error prop:
						// http://dev.apollodata.com/react/api-queries.html#graphql-query-data-error
						console.log(error) // eslint-disable-line
					}
					// getDataFromTree does not call componentWillUnmount
					// head side effect therefore need to be cleared manually
					Head.rewind()

					// Extract query data from the Apollo store
					serverState = {
						apollo: {
							data: apollo.cache.extract(),
						},
					}
				}

				return {
					serverState,
					xavierId,
					isMobile,
					isTablet,
					withDataFinished: true,
					...composedInitialProps,
				}
			}

			apollo: ApolloClient<NormalizedCacheObject>

			constructor(props: Props & T) {
				super(props)
				const {
					xavierId,
					serverState: {
						apollo: { data },
					},
				} = this.props
				this.apollo = initApollo(apolloConfig, data, null, {
					headers: { 'x-xavier-id': xavierId },
				})
			}

			render() {
				return (
					<ApolloProvider client={this.apollo}>
						<ComposedComponent {...this.props} />
					</ApolloProvider>
				)
			}
		}
	}
}

export default withApollo({})

import { ApolloClient, ApolloLink, NormalizedCacheObject } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import introspectionQueryResultData from '@aller/shared/graphql/fragmentTypes'
import isFunction from 'lodash.isfunction'

const possibleTypes: any = {}
// eslint-disable-next-line no-underscore-dangle
introspectionQueryResultData.__schema?.types?.forEach(
	(supertype: { name: string; possibleTypes: any }) => {
		if (supertype.possibleTypes) {
			possibleTypes[supertype.name] = supertype.possibleTypes.map((subtype: any) => subtype.name)
		}
	},
)

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null

interface Options {
	headers: { [header: string]: string }
}

const createDefaultCache = () => new InMemoryCache({ possibleTypes })

function create(apolloConfig: any, initialState: any, { headers }: Options): ApolloClient<any> {
	const createCache = apolloConfig.createCache || createDefaultCache

	const config = {
		connectToDevTools: process.browser,
		ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
		link: ApolloLink.from([
			onError(({ graphQLErrors, networkError }) => {
				if (graphQLErrors) {
					graphQLErrors.map(({ message, locations, path }) =>
						// eslint-disable-next-line no-console
						console.log(
							`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
						),
					)
				}

				if (networkError) console.log(`[Network error]: ${networkError}`) // eslint-disable-line
			}),
		]),
		cache: createCache().restore(initialState || {}),
		...apolloConfig,
	}

	delete config.createCache

	return new ApolloClient(config)
}

export default function initApollo(
	apolloConfig: any,
	initialState: any,
	ctx: any,
	options: any = {},
) {
	if (isFunction(apolloConfig)) {
		apolloConfig = apolloConfig(ctx)
	}

	if (!process.browser) {
		// Make sure to create a new client for every server-side request so that data
		// isn't shared between connections (which would be bad)
		return create(apolloConfig, initialState, options)
	}

	// Reuse client on the client-side
	if (!apolloClient) {
		apolloClient = create(apolloConfig, initialState, options)
	}

	return apolloClient
}
